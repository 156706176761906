import { AxiosPromise } from 'axios';
import config from '@app/config';
import {
  ConfirmCodeRequest,
  ConfirmCodeResponse,
  ConfirmEmailRequest,
  LoginKlueRequest,
  RegisterRequest,
  RegisterRequestV2,
  ValidateSchoolEmailRequest,
} from '@app/models/request/Auth';
import {
  FetchAuthInfoResponse,
  FindAuthInfoReponse,
  LoginKlueResponse,
} from '@app/models/response/Auth';
import ServerResponse from '@app/models/response/ServerResponse';
import School from '@app/models/School';
import { StorageValue } from '@app/models/storage';
import Api from './Api';
import ApiBuilder from './builder/ApiBuilder';
import StorageManager from '@app/utils/StorageManager';
import { REGISTER_EMAIL_TOKEN } from '@app/constants';

const authApiPrefix = `${config.apiHost}/auth`;
const authApiV2Prefix = `${config.apiV2Host}/auth`;
class AuthApi extends Api {
  apiEndPoints = {
    LOGIN_KLUE: `${authApiPrefix}/login`,
    LOGOUT_KLUE: `${authApiPrefix}/logout`,
    FETCH_AUTH_INFO: `${authApiPrefix}/info`,
    VALIDATE_USERNAME: `${authApiPrefix}/validation/username`,
    VALIDATE_NICKNAME: `${authApiPrefix}/validation/nickname`,
    VALIDATE_SCHOOL_EMAIL: `${authApiPrefix}/validation/school_email`,
    SEND_CONFIRM_EMAIL: `${authApiV2Prefix}/send-confirm-mail`,
    SEND_CONFIRM_CODE: `${authApiV2Prefix}/confirm-code`,
    FETCH_SCHOOL_LIST: `${authApiPrefix}/schools`,
    REGISTER_KLUE: `${authApiPrefix}/register`,
    REGISTER_KLUE_V2: `${authApiV2Prefix}/register`,
    UNREGISTER_KLUE: `${authApiPrefix}/unregister`,
    MIGRATION_KLUE: `${authApiPrefix}/migration`,
    CONFIRM_KLUE: `${authApiPrefix}/confirm`,
    FIND_USER: `${authApiPrefix}/find/username`,
    FIND_PWD: `${authApiPrefix}/find/password`,
    RESET_PWD: `${authApiPrefix}/reset/password`,
    REFRESH_ACCESS_TOKEN: `${authApiPrefix}/refreshAccessToken`,
  };

  loginKlue = (
    params: LoginKlueRequest
  ): AxiosPromise<ServerResponse<LoginKlueResponse>> => {
    const { username, password, remember } = params;

    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.LOGIN_KLUE)
      .data({ username, password, remember })
      .build();
  };

  logoutKlue = (): AxiosPromise => {
    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.LOGOUT_KLUE)
      .build();
  };

  fetchAuthInfo = (): AxiosPromise<ServerResponse<FetchAuthInfoResponse>> => {
    return ApiBuilder.create()
      .get()
      .url(this.apiEndPoints.FETCH_AUTH_INFO)
      .build();
  };

  validateUsername = (
    username: string
  ): AxiosPromise<ServerResponse<boolean>> => {
    return ApiBuilder.create()
      .get()
      .url(this.apiEndPoints.VALIDATE_USERNAME)
      .params({ username })
      .build();
  };

  validateNickname = (
    nickname: string
  ): AxiosPromise<ServerResponse<boolean>> => {
    return ApiBuilder.create()
      .get()
      .url(this.apiEndPoints.VALIDATE_NICKNAME)
      .params({ nickname })
      .build();
  };

  validateSchoolEmail = (
    params: ValidateSchoolEmailRequest
  ): AxiosPromise<ServerResponse<boolean>> => {
    const { school_email, school_id } = params;
    return ApiBuilder.create()
      .get()
      .url(this.apiEndPoints.VALIDATE_SCHOOL_EMAIL)
      .params({ school_email, school_id })
      .build();
  };

  sendConfirmEmail = (
    params: ConfirmEmailRequest
  ): AxiosPromise<ServerResponse<void>> => {
    const { email } = params;
    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.SEND_CONFIRM_EMAIL)
      .data({ email })
      .build();
  };

  sendConfirmCode = (
    params: ConfirmCodeRequest
  ): AxiosPromise<ConfirmCodeResponse> => {
    const { email, code } = params;
    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.SEND_CONFIRM_CODE)
      .data({ email, code })
      .build();
  };

  fetchSchoolList = (): AxiosPromise<ServerResponse<School[]>> => {
    return ApiBuilder.create()
      .get()
      .url(this.apiEndPoints.FETCH_SCHOOL_LIST)
      .build();
  };

  registerKlue = (params: RegisterRequest): AxiosPromise => {
    const {
      username,
      password,
      nickname,
      school_id,
      school_email,
      student_number,
      personality,
    } = params;

    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.REGISTER_KLUE)
      .data({
        username,
        password,
        nickname,
        school_id,
        school_email,
        student_number,
        personality,
      })
      .build();
  };

  registerKlueV2 = (params: RegisterRequestV2): AxiosPromise => {
    const {
      username,
      password,
      nickname,
      schoolId,
      studentNumber,
      personality,
    } = params;

    return ApiBuilder.create()
      .headers({
        Authorization: `${StorageManager.getItem(REGISTER_EMAIL_TOKEN)?.value}`,
      })
      .post()
      .url(this.apiEndPoints.REGISTER_KLUE_V2)
      .data({
        username,
        password,
        nickname,
        schoolId,
        studentNumber,
        personality,
      })
      .build();
  };

  migrateKlue = (
    username: string,
    password: string,
    nickname: string,
    personality: string,
    student_number: string
  ): AxiosPromise => {
    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.MIGRATION_KLUE)
      .data({
        username,
        password,
        nickname: nickname.length > 0 ? nickname : undefined,
        personality,
        student_number,
      })
      .build();
  };

  unregisterKlue = (): AxiosPromise => {
    return ApiBuilder.create()
      .put()
      .url(this.apiEndPoints.UNREGISTER_KLUE)
      .build();
  };

  confirmKlue = (token: string): AxiosPromise => {
    return ApiBuilder.create()
      .put()
      .url(this.apiEndPoints.CONFIRM_KLUE)
      .data({ token })
      .build();
  };

  findUsername = (
    school_email: string,
    school_id: string
  ): AxiosPromise<ServerResponse<FindAuthInfoReponse>> => {
    return ApiBuilder.create()
      .get()
      .url(this.apiEndPoints.FIND_USER)
      .params({ school_email, school_id })
      .build();
  };

  findPwd = (
    username: string
  ): AxiosPromise<ServerResponse<FindAuthInfoReponse>> => {
    return ApiBuilder.create()
      .get()
      .params({ username })
      .url(this.apiEndPoints.FIND_PWD)
      .build();
  };

  resetPwd = (token: string, password: string): AxiosPromise => {
    return ApiBuilder.create()
      .put()
      .url(`${this.apiEndPoints.RESET_PWD}/${token}`)
      .data({ password })
      .build();
  };

  refreshAccessToken = (refresh_token: StorageValue): AxiosPromise => {
    return ApiBuilder.create()
      .post()
      .url(this.apiEndPoints.REFRESH_ACCESS_TOKEN)
      .data({ refresh_token })
      .build();
  };
}

export default AuthApi;
